.projects-main {
  width: 100%;
  text-align: center;
}

.basic-projects {
  margin-left: 5%;
  margin-right: 5%;
}

.projects-heading-div {
  display: flex;
}

.projects-heading-div > * {
  flex: 1;
}

.projects-heading-img-div {
  align-items: center;
  justify-content: center;
}

.projects-heading-text-div {
  text-align: center;
}

.projects-heading-img-div > * {
  max-width: 100%;
  height: auto;
}

.projects-heading-text {
  font-size: 56px;
  font-weight: 400;
  font-family: "Google Sans Medium";
  line-height: 1.1;
  text-align: center;
  margin-top: 80px;
}

.projects-heading-sub-text {
  font-size: 30px;
  font-family: "Google Sans Regular";
  text-align: center;
  margin-bottom: 10px;
}

.projects-header-detail-text {
  font-size: 20px;
  line-height: 30px;
  margin-top: 0px;
  margin-left: 20px;
  margin-right: 20px;
  font-family: "Google Sans Medium";
}

.repo-cards-div-main {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  grid-row-gap: 1rem;
  grid-column-gap: 1rem;  
  margin-left: 40px;
  margin-right: 40px;
  margin-top: 60px;
}

.projects-filter {
  display: flex;
  margin: 60px 10px 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.projects-filter .button {
  background-color: #dce4f2;
  color: black;
  width: unset;
  height: unset;
  margin: 0;
}

.projects-filter .button:hover {
  box-shadow: 0 5px 15px #dce4f2;
}

.projects-filter .button.active {
  background-color: #e3405f;
  color: #fff;
}

@media (max-width: 1380px) {
  .projects-heading-text {
    font-size: 50px;
    margin-top: 80px;
  }
  .projects-heading-sub-text {
    font-size: 25px;
  }
}

@media screen and (max-width: 1224px) {
  .repo-cards-div-main {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

@media screen and (max-width: 768px) {
  .projects-heading-text {
    font-size: 30px;
    margin-top: 20px;
  }

  .projects-heading-sub-text {
    font-size: 20px;
  }

  .projects-heading-div {
    flex-direction: column;
  }

  .projects-header-detail-text {
    font-size: 16px;
    margin-right: 0px;
    margin-left: 0px;
    line-height: normal;
    text-align: center;
  }
  
  .repo-cards-div-main {
    grid-template-columns: repeat(2, minmax(0, 1fr));;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 30px;
  }
}

@media screen and (max-width: 600px) {
  .repo-cards-div-main {
    grid-template-columns: 100%;
  }
}
