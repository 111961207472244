.dev-icons {
  padding-left: 0;
  list-style: none;
  font-size: 3rem;
  margin-bottom: 0px;
  margin-top: 0px;
  text-align: center;
}

.software-skill-inline {
  display: inline-block;
  margin-right: 20px;
  margin-bottom: 20px;
  font-family: "Google Sans Regular";
}

.software-skill-inline > i {
  color: #868e96;
}

.software-skill-inline > i:hover {
  color: #645beb;
}

@media (max-width: 768px) {
  .software-skill-inline {
    margin: 0 5px 10px 5px;
  }

  .dev-icons {
    font-size: 2rem;
  }

  .dev-icons li .logo_img {
    height: 2rem !important;
  }
}
