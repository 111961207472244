.skills-main-div {
  display: flex;
}

#skills > div > .skills-main-div {
  margin-bottom: 100px;
}

#skills > div > .skills-main-div:last-child {
  margin-bottom: 10px;
}

.skills-header {
  font-size: 60px;
  line-height: 1.1;
  font-family: "Google Sans Regular";
  text-align: center;
}

.skills-text {
  font-size: 20px;
}

.skills-text-div {
  margin-left: 50px;
  margin-top: 3%;
  /* padding-top: 80px; */
  font-family: "Google Sans Regular";
}

.skills-main-div > * {
  flex: 1;
  margin-bottom: 30px;
}

.skills-image-div > * {
  max-width: 100%;
  height: auto;
  /* margin-top: 100px; */
}

.skills-heading {
  font-size: 50px;
  font-weight: 400;
  text-align: center;
  padding-bottom: 10px;
  font-family: "Google Sans Regular";
}

/* Media Query */
@media (max-width: 1380px) {
  .skills-heading {
    font-size: 40px;
  }
  .skills-header {
    font-size: 50px;
  }
}
@media (max-width: 768px) {
  .skills-heading {
    font-size: 24px;
    text-align: center;
  }
  .skills-header {
    font-size: 30px;
  }
  .greeting-text-p {
    font-size: 16px;
    line-height: normal;
  }
  .skills-main-div {
    flex-direction: column;
  }

  #skills > div > .skills-main-div {
    margin-bottom: 20px;
    border-bottom: 1px solid #7f8daa;
  }
  
  #skills > div > .skills-main-div:last-child {
    margin-bottom: 10px;
    border-bottom: none;
  }

  .skills-text-div {
    margin: 20px 0;

  }
  .skills-text {
    font-size: 16px;
  }
  .skills-text-subtitle {
    font-size: 16px;
    text-align: center;
  }
  .skills-image-div {
    order: 2;
    text-align: center;
  }
  .skills-image-div > * {
    margin-top: 0px;
  }
}
